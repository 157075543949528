<style scoped>
.st0 {
  fill: #6472f6;
}
.st1 {
  fill: #ffffff;
}
</style>
<template>
  <!-- Generator: Adobe Illustrator 27.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="레이어_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 163.02 106.84"
    style="enable-background: new 0 0 163.02 106.84"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M109.52,12.18c-0.54-0.32-1.08-0.57-1.62-0.75c-2.35-0.77-4.83,0.56-6.83,1.68
		c-3.66,2.05-7.92,3.52-11.82,5.13c-4.08,1.69-8.32,3.12-12.51,4.53c-12.42,4.18-24.49,9.97-36.34,15.6
		C34,41.41,26.85,43.58,20.73,47.1c-3.34,1.92-8.18,2.73-9.62,6.95c-0.91,2.65-0.52,6.11-0.74,8.88C9.92,68.4,10.3,73.94,10.62,79.4
		c0.25,4.24-1.32,8.83,3.75,10.27c3.5,0.99,7.23,1.09,10.83,1.13c11.48,0.12,22.97-0.23,34.45-0.25c7.17-0.01,14.7,1.11,21.83,0.43
		c6.22-0.6,12.56,0.28,18.87-0.19c9.31-0.69,18.64-0.82,27.96-1.22c4.62-0.19,9.22-0.57,13.83-0.81c1.91-0.1,3.67,0.31,4.55-1.67
		c0.72-1.63,0.37-5.12,0.56-6.96c0.35-3.23,0.79-6.56,1.01-9.86c0.34-4.9-0.2-9.81-0.08-14.71c0.12-4.63,0.6-8.18-0.36-12.76
		c-0.29-1.38-0.51-2.27-1.26-3.38c-1.14-1.68-3.46-2.93-5.05-4.14c-2.05-1.56-4.09-3.11-6.15-4.65c-4.23-3.15-8.81-5.87-13-9.06
		C118.12,18.33,114.16,14.91,109.52,12.18z"
      />
      <path
        d="M76.44,92.2c-2.82,0-5.66-0.17-8.45-0.33c-2.76-0.16-5.58-0.3-8.34-0.32c-4.71,0.01-9.5,0.07-14.13,0.14
		c-6.66,0.09-13.55,0.19-20.34,0.11c-3.82-0.04-7.54-0.16-11.09-1.17c-4.72-1.34-4.6-5.14-4.48-8.49c0.03-0.89,0.06-1.81,0.01-2.69
		L9.57,78.6c-0.31-5.16-0.63-10.5-0.21-15.76c0.07-0.85,0.08-1.78,0.09-2.76c0.02-2.17,0.04-4.41,0.71-6.36
		c1.21-3.51,4.44-4.92,7.29-6.16c1-0.44,1.94-0.85,2.78-1.33c3.74-2.15,7.88-3.81,11.88-5.42c2.62-1.06,5.34-2.15,7.87-3.35
		L41,36.97c11.46-5.45,23.31-11.08,35.43-15.16c3.9-1.31,8.32-2.8,12.44-4.51c1.02-0.42,2.05-0.83,3.1-1.24
		c2.93-1.15,5.96-2.35,8.61-3.83c1.91-1.07,4.79-2.69,7.63-1.75c0.59,0.19,1.18,0.47,1.82,0.84l0,0c3.52,2.07,6.7,4.57,9.77,6.99
		c1.05,0.83,2.1,1.65,3.17,2.47c2.18,1.66,4.53,3.24,6.79,4.76c2.05,1.38,4.17,2.8,6.19,4.3c2.07,1.54,4.11,3.09,6.16,4.65
		c0.42,0.32,0.89,0.64,1.38,0.97c1.39,0.95,2.96,2.02,3.9,3.4c0.84,1.24,1.1,2.24,1.41,3.74c0.78,3.71,0.64,6.83,0.49,10.13
		c-0.04,0.92-0.08,1.87-0.11,2.87c-0.05,2.09,0.02,4.22,0.09,6.29c0.09,2.77,0.19,5.63-0.01,8.46c-0.16,2.38-0.44,4.8-0.71,7.13
		c-0.11,0.93-0.21,1.85-0.31,2.77c-0.06,0.58-0.06,1.37-0.07,2.21c-0.01,1.86-0.01,3.78-0.57,5.05c-0.98,2.22-2.92,2.24-4.47,2.24
		c-0.31,0-0.62,0-0.94,0.02c-1.95,0.1-3.89,0.23-5.84,0.35c-2.62,0.17-5.33,0.35-8.01,0.46c-2.96,0.12-5.93,0.22-8.89,0.32
		c-6.25,0.21-12.71,0.42-19.04,0.89c-3.07,0.23-6.17,0.14-9.16,0.06c-3.2-0.09-6.5-0.18-9.69,0.12
		C79.88,92.14,78.17,92.2,76.44,92.2z M59.78,89.55c2.75,0,5.59,0.16,8.33,0.32c4.41,0.25,8.97,0.52,13.28,0.11
		c3.31-0.32,6.67-0.22,9.93-0.13c2.94,0.08,5.99,0.17,8.96-0.06c6.37-0.48,12.85-0.69,19.12-0.9c2.96-0.1,5.92-0.2,8.87-0.32
		c2.65-0.11,5.35-0.29,7.96-0.46c1.95-0.13,3.91-0.25,5.86-0.36c0.35-0.02,0.7-0.02,1.04-0.02c1.66-0.01,2.23-0.09,2.65-1.05
		c0.39-0.89,0.4-2.75,0.4-4.24c0-0.9,0.01-1.74,0.08-2.42c0.1-0.92,0.2-1.85,0.31-2.78c0.26-2.31,0.54-4.7,0.7-7.04
		c0.19-2.73,0.09-5.54,0-8.26c-0.07-2.1-0.14-4.26-0.09-6.41c0.03-1.01,0.07-1.97,0.11-2.91c0.15-3.31,0.28-6.16-0.45-9.63
		c-0.27-1.29-0.46-2.07-1.11-3.03c-0.73-1.07-2.07-1.98-3.37-2.87c-0.52-0.35-1.02-0.69-1.46-1.03c-2.04-1.55-4.09-3.11-6.15-4.64
		c-1.98-1.47-4.08-2.88-6.11-4.24c-2.29-1.54-4.66-3.12-6.89-4.83c-1.08-0.82-2.14-1.66-3.2-2.49c-3.16-2.49-6.14-4.84-9.55-6.84
		l0,0c-0.51-0.3-0.98-0.52-1.43-0.66c-1.85-0.61-3.91,0.41-6.03,1.6c-2.77,1.55-5.86,2.77-8.85,3.95c-1.04,0.41-2.07,0.81-3.07,1.23
		c-4.19,1.73-8.64,3.23-12.57,4.55c-12.01,4.04-23.8,9.65-35.21,15.07l-1.02,0.49c-2.59,1.23-5.33,2.33-7.98,3.4
		c-3.94,1.59-8.01,3.23-11.63,5.3c-0.93,0.54-1.97,0.99-2.98,1.43c-2.71,1.18-5.28,2.3-6.2,4.98c-0.56,1.64-0.58,3.72-0.6,5.73
		c-0.01,1.02-0.02,1.98-0.09,2.91c-0.41,5.12-0.1,10.38,0.21,15.48l0.05,0.86c0.06,0.97,0.03,1.94-0.01,2.87
		c-0.12,3.57-0.04,5.62,3.03,6.5c3.32,0.94,6.89,1.05,10.57,1.09c6.76,0.07,13.64-0.02,20.29-0.11c4.64-0.06,9.43-0.13,14.15-0.14
		C59.7,89.55,59.74,89.55,59.78,89.55z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M131.38,68.44c-3.17,0.25-6.26,0.86-9.42,1.13c-0.45,0.04-2.69,0.45-2.99-0.08c-1.01-1.81-2.69-3.17-4.6-3.93
		c-2.18-0.87-5.87-0.6-7.72,0.99c-0.75,0.65-1.52,1.59-2.02,2.44c-1.79,3.06-2.29,8.85,0.32,11.66c4.01,4.33,10.73,4.29,15.36-0.7
		c1.35,0.41,2.8,0.18,4.18,0.13c1.36-0.05,4.84-0.15,6.43-0.3"
      />
      <path
        d="M131.24,80.69c-0.07,0.02-0.15,0.04-0.23,0.05c-1.37,0.13-4.1,0.22-5.73,0.27l-0.75,0.03c-0.29,0.01-0.59,0.03-0.88,0.05
		c-0.98,0.06-2,0.13-3.02-0.07c-2.42,2.42-5.44,3.75-8.55,3.75c-2.99,0-5.77-1.23-7.84-3.46c-2.87-3.1-2.54-9.24-0.45-12.82
		c0.57-0.98,1.41-1.99,2.23-2.69c2.21-1.89,6.29-2.12,8.72-1.15c2.11,0.84,3.86,2.3,4.96,4.13c0.24,0.03,0.75,0.01,1.8-0.13
		c0.17-0.02,0.31-0.04,0.39-0.05c1.5-0.13,3.01-0.34,4.48-0.55c1.61-0.22,3.27-0.46,4.95-0.59c0.54-0.04,1.01,0.36,1.05,0.9
		c0.04,0.54-0.36,1.01-0.9,1.05c-1.61,0.13-3.25,0.35-4.83,0.58c-1.49,0.21-3.04,0.42-4.58,0.56c-0.06,0.01-0.17,0.02-0.3,0.04
		c-1.67,0.22-3.09,0.33-3.63-0.62c-0.87-1.54-2.33-2.79-4.11-3.5c-1.81-0.73-5.14-0.53-6.72,0.82c-0.65,0.56-1.35,1.4-1.81,2.19
		c-1.71,2.92-1.99,8.14,0.19,10.5c1.7,1.83,3.97,2.84,6.4,2.84c2.73,0,5.4-1.26,7.52-3.54c0.25-0.28,0.64-0.38,1-0.27
		c0.89,0.27,1.88,0.21,2.93,0.14c0.31-0.02,0.63-0.04,0.94-0.05l0.76-0.03c1.61-0.05,4.3-0.15,5.62-0.27
		c0.54-0.05,1.02,0.35,1.06,0.88C131.94,80.14,131.66,80.55,131.24,80.69z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M17.82,68.79c3.15,0.45,3.05,0.81,6.18,1.29c0.45,0.07,2.14,0.55,2.47,0.04c1.13-1.74,3.41-2.92,5.37-3.56
		c2.23-0.73,5.9-0.22,7.64,1.48c0.71,0.69,1.41,1.68,1.86,2.56c1.59,3.17,1.3,8.96-1.49,11.6c-4.28,4.06-12.72,3.02-15.39-2.98
		c-1.38,0.32-2.81,0-4.18-0.14c-1.35-0.14-1.87,0.03-3.44-0.22"
      />
      <path
        d="M16.38,79.74c-0.37-0.19-0.6-0.6-0.53-1.03c0.08-0.53,0.59-0.9,1.12-0.81c0.84,0.13,1.35,0.14,1.9,0.14
		c0.43,0,0.88,0.01,1.49,0.07c0.31,0.03,0.62,0.07,0.94,0.11c1.04,0.13,2.02,0.26,2.93,0.05c0.46-0.11,0.93,0.13,1.12,0.55
		c1.07,2.4,3.29,4.09,6.08,4.65c2.88,0.57,5.85-0.18,7.74-1.98c2.45-2.32,2.7-7.62,1.28-10.45c-0.41-0.82-1.05-1.7-1.66-2.3
		c-1.48-1.45-4.79-1.86-6.65-1.25c-1.07,0.35-3.68,1.36-4.85,3.16c-0.64,0.99-2.15,0.67-3.15,0.46c-0.12-0.02-0.22-0.05-0.28-0.06
		c-1.59-0.24-2.39-0.46-3.15-0.66c-0.76-0.21-1.48-0.4-3.02-0.62c-0.54-0.08-0.91-0.57-0.83-1.11c0.08-0.54,0.57-0.91,1.11-0.83
		c1.66,0.24,2.47,0.46,3.25,0.67c0.75,0.2,1.45,0.39,2.93,0.62c0.09,0.01,0.23,0.04,0.4,0.08c0.24,0.05,0.91,0.19,1.25,0.19
		c1.49-2.11,4.22-3.25,5.74-3.75c2.48-0.81,6.54-0.32,8.63,1.71c0.77,0.75,1.54,1.81,2.05,2.82c1.79,3.57,1.45,9.78-1.69,12.75
		c-2.34,2.22-5.97,3.17-9.47,2.48c-3.16-0.63-5.73-2.46-7.15-5.09c-0.97,0.1-1.92-0.02-2.84-0.14c-0.3-0.04-0.59-0.08-0.88-0.11
		C19.64,80,19.24,80,18.86,80c-0.59-0.01-1.2-0.01-2.18-0.17C16.57,79.81,16.47,79.78,16.38,79.74z"
      />
    </g>
    <g>
      <path
        class="st1"
        d="M50.4,65.26c-2.1,0.85-5.35,5.67-2.78,7.53c2.87,2.08,6.24-1.4,7.71-3.6"
      />
      <path
        d="M49.53,74.43c-0.89,0-1.74-0.28-2.5-0.83c-1.03-0.74-1.5-1.92-1.32-3.31c0.31-2.5,2.57-5.25,4.32-5.95
		c0.51-0.21,1.1,0.04,1.3,0.55c0.21,0.51-0.04,1.1-0.55,1.3c-1.06,0.43-2.86,2.54-3.08,4.34c-0.11,0.89,0.24,1.25,0.51,1.45
		c0.54,0.39,1.11,0.52,1.75,0.41c1.7-0.3,3.51-2.21,4.54-3.75c0.31-0.46,0.93-0.58,1.39-0.27c0.46,0.31,0.58,0.93,0.27,1.39
		c-1.31,1.96-3.53,4.2-5.85,4.61C50.05,74.4,49.79,74.43,49.53,74.43z"
      />
    </g>
    <g>
      <path
        class="st1"
        d="M57.84,50.12c-1.63,0.32-3.24,1.11-4.64,2.2c-2.39,1.85-3.21,4.78-3.17,7.69c0.04,3.02,0.42,6.87,2.57,9.24
		c2.77,3.05,9.88,2.97,12.28-0.63c2.95-4.42,3.42-11.78-0.01-15.97C62.92,50.25,60.35,49.63,57.84,50.12z"
      />
      <path
        d="M58.47,72.43c-2.7,0-5.17-0.93-6.61-2.52c-2.45-2.7-2.79-6.94-2.83-9.9c-0.05-3.72,1.18-6.66,3.55-8.5
		c1.58-1.22,3.33-2.05,5.06-2.39l0,0c3.14-0.61,5.99,0.41,8.01,2.88c3.91,4.79,3.08,12.63,0.06,17.16
		c-1.23,1.84-3.55,3.02-6.37,3.23C59.06,72.42,58.76,72.43,58.47,72.43z M59.39,50.96c-0.49,0-0.95,0.05-1.37,0.14l0,0
		c-1.43,0.28-2.89,0.97-4.22,2.01c-2.35,1.82-2.81,4.75-2.78,6.89c0.04,2.63,0.31,6.38,2.31,8.58c1.17,1.29,3.47,2.01,5.86,1.83
		c2.17-0.16,3.98-1.04,4.85-2.34c2.63-3.95,3.28-10.83,0.05-14.78C62.62,51.47,60.88,50.96,59.39,50.96z M57.84,50.12L57.84,50.12
		L57.84,50.12z"
      />
    </g>
    <g>
      <path
        d="M62.82,62.38c-0.19,0.64-0.44,1.26-0.7,1.85c-1.15,2.57-3.75,3.15-6.06,1.73c-1.43-0.88-1.54-3.05-1.61-4.65
		c-0.09-1.93,0.35-4.28,1.76-5.58c1.69-1.56,4.05-1.31,5.64,0.29C63.57,57.76,63.49,60.17,62.82,62.38z"
      />
      <path
        d="M58.55,67.72c-1.01,0-2.04-0.31-3.02-0.91c-1.89-1.17-2.01-3.75-2.09-5.46c-0.12-2.66,0.66-5.04,2.08-6.36
		c2.03-1.88,4.99-1.74,7.03,0.33c1.72,1.74,2.14,4.28,1.21,7.35c-0.19,0.63-0.44,1.29-0.74,1.97c-0.65,1.44-1.74,2.46-3.09,2.87
		C59.5,67.65,59.03,67.72,58.55,67.72z M58.83,55.68c-0.69,0-1.37,0.26-1.94,0.78c-1.17,1.09-1.52,3.19-1.44,4.8
		c0.06,1.29,0.15,3.23,1.14,3.85c0.94,0.58,1.92,0.75,2.77,0.49c0.8-0.24,1.44-0.86,1.85-1.78c0.27-0.6,0.49-1.18,0.65-1.73
		c0,0,0,0,0,0c0.71-2.36,0.47-4.16-0.72-5.37C60.46,56.03,59.64,55.68,58.83,55.68z"
      />
    </g>
    <g>
      <path
        class="st1"
        d="M85.79,69.21c0.57,3.19,6.52,5.66,7.62,1.29c0.67-2.64-1.51-4.54-2.43-5.84"
      />
      <path
        d="M90.56,73.84c-0.06,0-0.11,0-0.17,0c-2.56-0.09-5.15-2.17-5.56-4.45c-0.09-0.53,0.26-1.04,0.79-1.13
		c0.53-0.1,1.04,0.26,1.13,0.79c0.25,1.38,2.08,2.78,3.71,2.84c1.07,0.03,1.72-0.49,2.01-1.61c0.43-1.69-0.67-3.04-1.64-4.22
		c-0.24-0.29-0.46-0.56-0.64-0.81c-0.31-0.44-0.21-1.05,0.23-1.36c0.44-0.31,1.05-0.21,1.36,0.23c0.15,0.22,0.35,0.45,0.56,0.71
		c1.07,1.31,2.69,3.29,2.03,5.94C93.87,72.69,92.45,73.84,90.56,73.84z"
      />
    </g>
    <g>
      <path
        class="st1"
        d="M91.33,63.66c-0.66,2-1.53,3.98-2.91,5.59c-2.4,2.79-7.24,2.74-10.3,1.02c-2.86-1.61-3.08-5.56-3.22-8.48
		c-0.2-4.1,1.19-9.53,5.33-11.3c2.27-0.97,5.03-0.45,7.13,0.74c1.42,0.8,2.81,2.12,3.44,3.65c0.86,2.11,1.31,4.57,1,6.84
		C91.72,62.31,91.56,62.97,91.33,63.66z"
      />
      <path
        d="M82.9,72.47c-1.88,0-3.77-0.48-5.27-1.33c-3.37-1.9-3.59-6.35-3.73-9.3c-0.23-4.74,1.51-10.38,5.94-12.27
		c2.36-1.01,5.36-0.71,8.02,0.79c1.77,1,3.22,2.55,3.87,4.14c1,2.45,1.38,5.07,1.06,7.36c-0.09,0.65-0.26,1.36-0.51,2.11l0,0
		c-0.85,2.57-1.83,4.45-3.1,5.93C87.63,71.69,85.27,72.47,82.9,72.47z M82.82,50.99c-0.75,0-1.49,0.13-2.2,0.43
		c-3.54,1.51-4.93,6.28-4.73,10.33c0.13,2.66,0.31,6.3,2.71,7.65c2.72,1.53,7.01,1.57,9.05-0.8c1.1-1.27,1.96-2.94,2.72-5.25l0,0
		c0.21-0.63,0.35-1.23,0.43-1.75c0.27-1.95-0.06-4.2-0.94-6.33c-0.48-1.18-1.61-2.36-3-3.15C85.78,51.49,84.31,50.99,82.82,50.99z"
      />
    </g>
    <g>
      <path
        d="M87.54,62.45c-0.2,0.69-0.56,1.38-1.12,2.08c-1.54,1.94-3.55,2.82-5.79,1.44c-1.43-0.88-1.54-3.05-1.61-4.65
		c-0.09-1.93,0.35-4.28,1.76-5.58c2.02-1.86,5.25-0.74,6.19,1.79C87.66,59.41,87.97,60.95,87.54,62.45z"
      />
      <path
        d="M82.79,67.64c-0.89,0-1.8-0.27-2.69-0.82c-1.89-1.17-2.01-3.75-2.09-5.46c-0.12-2.67,0.66-5.04,2.09-6.36
		c1.12-1.04,2.64-1.42,4.16-1.06c1.65,0.4,3.05,1.63,3.65,3.23c0.61,1.65,1.16,3.6,0.6,5.55l0,0c-0.24,0.84-0.67,1.63-1.3,2.42
		C85.89,66.8,84.37,67.64,82.79,67.64z M83.14,55.81c-0.5,0-1.12,0.14-1.68,0.66c-1.18,1.09-1.52,3.19-1.44,4.8
		c0.06,1.29,0.15,3.23,1.14,3.85c0.58,0.36,2.37,1.46,4.49-1.21c0.47-0.59,0.77-1.16,0.94-1.74c0.33-1.12,0.17-2.37-0.55-4.3
		c-0.37-0.98-1.22-1.74-2.24-1.99C83.62,55.85,83.39,55.81,83.14,55.81z M87.54,62.45L87.54,62.45L87.54,62.45z"
      />
    </g>
  </svg>
</template>
